closeCurtain = () => {
  var cList = document.querySelector('body').classList;
  cList.add('curtain--open');
  setTimeout(() => {
    cList.add('curtain--closing')
    cList.remove('curtain--open');
  }, 10);
}

clickCurtainLinkCallback = (e) => {
  var e = window.e || e;
  if (e.target.tagName !== 'A' || e.ctrlKey || e.altKey || e.shiftKey) return;

  // No curtain for _blank links;
  if (e.target.target == '_blank') return;

  // Don't want to close curtain on bookmark links;
  var wl = window.location
  if (e.target.href.startsWith(wl.href.replace(wl.hash, '') + '#')) return;

  e.preventDefault();
  closeCurtain();

  setTimeout(() => {
    window.location = e.target.href;
  }, 300);
}

if (document.addEventListener) {
  document.addEventListener('click', clickCurtainLinkCallback, false);
  document.addEventListener('beforeunload', closeCurtain, false);
} else {
  document.attachEvent('onclick', clickCurtainLinkCallback);
  document.attachEvent('beforeunload', closeCurtain);
}
