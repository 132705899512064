onload = (event) => {
  removeCurtainOnload();
  loadHeader();
  productHover();
  loadVideoSrc();

  document.querySelectorAll('.underlined--start').forEach((item) => {
    item.classList.remove('underlined--start');
  });

  imageSwapper();
  menuToggle();
  initVideoModal();
}

removeCurtainOnload = () => {
  document.querySelector('body').classList.remove('curtain--opening');
}

loadHeader = () => {
  let header = document.getElementsByTagName('header')[0];
  if (!header) return;

  let doc = document.documentElement,
      prevScroll = window.scrollY || doc.scrollTop,
      curScroll,
      direction = 0,
      prevDirection = 0,
      langSwitcher = document.getElementById('lang');

  let checkScroll = () => {
    curScroll = window.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) {
      //scrolled up
      direction = 'down';
    }
    else if (curScroll < prevScroll) {
      //scrolled down
      direction = 'up';
    }

    if (direction !== prevDirection) toggleHeader(direction, curScroll);
    prevScroll = curScroll;
  };

  let toggleHeader = (direction, curScroll) => {
    if (direction == 'down' && curScroll > header.offsetHeight) {
      if (header) header.classList.add('header--hide');
      if (langSwitcher) langSwitcher.classList.remove('open');
      prevDirection = direction;
    }
    else if (direction == 'up') {
      if (header) header.classList.remove('header--hide');
      prevDirection = direction;
    }
  };

  window.addEventListener('scroll', checkScroll);
}


productHover = () => {
  let products = document.querySelectorAll(".products .products__item"),
      video = document.getElementById('productsVideo');

  products.forEach(product => {
    // Show and play on hover
    product.addEventListener("mouseenter", function() {
      let vidType = product.dataset.vidType,
          vidSrc = product.dataset.vidSrc;

      if (vidSrc && window.innerWidth >= 1280) {
        video.type = vidType;
        video.src = vidSrc;
        video.classList.add('video-preview--active');
      } else {
        video.classList.remove('video-preview--active');
        video.src = '';
        video.type = '';
      }
    });

    // Pause and hide on mouse leave
    product.addEventListener("mouseleave", function() {
      video.classList.remove('video-preview--active');
      video.src = '';
      video.type = '';
    });
  });
}

scrollToTop = (top) => {
  top = top || 0;
  document.body.scrollTop = top;
  document.documentElement.scrollTop = top;
}

// scrollToVideo = () => {
//   location.hash = "#video";
//   let videoSection = document.getElementById('video');
//   if (videoSection) {
//     console.log('scrollToVideo', videoSection.offsetTop);
//     scrollToTop(videoSection.offsetTop);
//   }
//
// }
openModal = (target) => {
  let targetModal = document.querySelector('[data-bs-target="#'+target+'"]');
  if (targetModal) {
    setTimeout(() => { targetModal.click() }, 400);
  }
}

menuToggle = () => {
  let buttons = document.querySelectorAll("button[data-toggle]"),
      body = document.body;

  buttons.forEach(btn => {
    btn.addEventListener("click", function() {
      let targetId = this.dataset.toggle,
          ariaControls = this.getAttribute('aria-controls'),
          target = document.getElementById(targetId),
          targetMenu = document.getElementById(ariaControls);

      target.classList.toggle('open');
      let isOpen = target.classList.contains('open');

      if (targetMenu) targetMenu.ariaHidden = !isOpen;
      this.ariaExpanded = isOpen;

      if (targetId == 'mainMenu') body.classList.toggle('menu-open');
    });
  });
}



imageSwapper = ()=> {
  [].map.call(document.querySelectorAll('.image-swap'), function(el) {
    let images = el.querySelectorAll('.image-swap__item'),
        interval = el.dataset.interval || 500,
        maxImgIndex = images.length - 1,
        currentImgIndex = 0,
        nextImgIndex;

    setInterval(() => {
      nextImgIndex = currentImgIndex + 1;
      if (nextImgIndex > maxImgIndex) nextImgIndex = 0;

      images[currentImgIndex].style.display = 'none';
      images[nextImgIndex].style.display = 'block';

      currentImgIndex = nextImgIndex;
    }, interval);
  });
}

initVideoModal = () => {
  let vidModal = document.getElementById('vidModal');
  if (vidModal) {
    vidModal.addEventListener('show.bs.modal', () => {
      let iframe = vidModal.querySelector('iframe'),
          src = iframe.getAttribute('src');
      if (iframe.dataset.src) {
        iframe.src = iframe.dataset.src;
      }
    });

    vidModal.addEventListener('hide.bs.modal', () => {
      let iframe = vidModal.querySelector('iframe'),
          src = iframe.getAttribute('src');

      iframe.dataset.src = src;
      iframe.src = "";
    });
  };
}

loadVideoSrc = () => {
  [].map.call(document.querySelectorAll('[data-video-src]'), function(el) {
    let videoTag = el.querySelector('video'),
        src = el.dataset.videoSrc;

    if (!videoTag) return;

    videoTag.src = src;
  });
}
